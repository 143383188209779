import axios from "axios";
import { TTP_API_URL } from "../lib/consts";
import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
  throwCatchedError,
} from "../lib/utils";

let getTagsCTS;

export const fetchTags = ({
  token,
  start,
  limit,
  notHandled,
  notValidated,
  hasSynonyms,
  hideSynonyms,
  isSuperTag,
  search,
  similarsFr,
  similarsEn,
  similarsNl,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getTagsCTS,
    cancellationTokenSource
  );
  getTagsCTS = cancellationTokenSource;
  const requestUrl = `${TTP_API_URL}/blog/tag`;

  const filters = [
    {
      property: "fromTagsApp",
      value: 1,
      operator: "eq",
    },
  ];
  if (notHandled) {
    filters.push({
      property: "isHandled",
      value: "0",
      operator: "eq",
    });
  }
  if (notValidated) {
    filters.push({
      property: "state",
      value: "TO_VALIDATE",
      operator: "like",
    });
  }
  if (hasSynonyms) {
    filters.push({
      property: "hasSynonyms",
      value: "1",
      operator: "eq",
    });
  }
  if (hideSynonyms) {
    filters.push({
      property: "hideSynonyms",
      value: "1",
      operator: "eq",
    });
  }
  if (isSuperTag) {
    filters.push({
      property: "isSuperTag",
      value: "1",
      operator: "eq",
    });
  }
  if (search) {
    filters.push({
      property: "search",
      value: search,
      operator: "like",
    });
  }

  if (similarsFr) {
    filters.push({
      property: "similarsFr",
      value: similarsFr,
      operator: "eq",
    });
  }
  if (similarsEn) {
    filters.push({
      property: "similarsEn",
      value: similarsEn,
      operator: "eq",
    });
  }
  if (similarsNl) {
    filters.push({
      property: "similarsNl",
      value: similarsNl,
      operator: "eq",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    start,
    limit,
    fields: "*,synonyms,parent,theme,pages,superTag",
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const cleanTags = ({ token }) => {
  const requestUrl = `${TTP_API_URL}/blog/tag/duplicate/delete`;

  var formData = new FormData();
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const mergeTags = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/blog/tag/merge`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("source", data.source);
  formData.append("destination", data.destination);
  formData.append("full_merge", data.fullMerge);

  return axios.post(requestUrl, formData);
};

export const validateTag = ({ token, id }) => {
  const requestUrl = `${TTP_API_URL}/blog/tag/validate/${id}`;

  var formData = new FormData();
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const deleteTag = ({ token, id }) => {
  const requestUrl = `${TTP_API_URL}/blog/tag/${id}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const translateTag = ({ token, text, lng }) => {
  const requestUrl = `${TTP_API_URL}/blog/tag/translate`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("text", text);
  formData.append("lang", lng);

  return axios.post(requestUrl, formData);
};

export const getSynonyms = ({ token, text }) => {
  const requestUrl = `${TTP_API_URL}/blog/tag/detect-synonyms`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("text", text);
  formData.append("lang", "fr");

  return axios.post(requestUrl, formData);
};

export const saveTag = ({ token, data }) => {
  const requestUrl = `${TTP_API_URL}/blog/tag`;

  var formData = new FormData();

  formData.append("access_token", token);
  if (data.id) formData.append("id", data.id);
  formData.append("nameFr", data.nameFr);
  formData.append("nameEn", data.nameEn);
  formData.append("nameNl", data.nameNl);
  if (data.isSynonymOf) formData.append("isSynonymOf", data.isSynonymOf);
  formData.append("status", data.status);
  formData.append("state", data.state);
  formData.append("isHandled", data.isHandled ? 1 : 0);
  formData.append("isAbbreviation", data.isAbbreviation ? 1 : 0);
  formData.append("isUniversal", data.isUniversal ? 1 : 0);
  formData.append("isSuperTag", data.isSuperTag ? 1 : 0);
  if (data.theme) {
    formData.append("theme", data.theme);
  }
  if (data.synonyms.length > 0) {
    data.synonyms.forEach((syn, i) => {
      formData.append(`synonyms[${i}][id]`, syn.id);
      formData.append(`synonyms[${i}][nameFr]`, syn.nameFr);
      formData.append(`synonyms[${i}][nameEn]`, syn.nameEn);
      formData.append(`synonyms[${i}][nameNl]`, syn.nameNl);
    });
  }
  if (data.pages && data.pages.length > 0) {
    for (let i = 0; i < data.pages.length; i++) {
      formData.append(`pages[${i}]`, data.pages[i].id);
    }
  }
  if (data.superTag) {
    formData.append(`superTag`, data.superTag);
  }
  return axios.post(requestUrl, formData);
};

export const getThemesList = ({ token, start = null, id }) => {
  const requestUrl = `${TTP_API_URL}/blog/theme`;

  let filters = [];

  if (id) {
    filters.push({
      property: "id",
      value: id,
      operator: "eq",
    });
  } else {
    filters.push({
      property: "isDefault",
      value: 0,
      operator: "eq",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*",
    limit: 20,
    start,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getPages = ({ token, theme, ids }) => {
  const requestUrl = `${TTP_API_URL}/blog/page`;

  let filters = [];
  if (theme) {
    filters.push({
      property: "theme",
      value: theme,
      operator: "eq",
    });
  }

  if (ids) {
    filters.push({
      property: "id",
      value: ids,
      operator: "in",
    });
  }
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
      fields: "*",
    },
  });
};

export const saveSuperTag = (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/tag`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("superTag", data.superTag);

  return axios.post(requestUrl, formData);
};
