import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import styles from "./MergeModal.module.scss";
import _ from "../../../i18n";
import { mergeTags } from "../../../api";
import Loader from "../Loader";

const MergeModal = ({ modalOpen, onClose, tags, afterMerge }) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { language, token } = auth;
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [destination, setDestination] = useState(null);
  const [fullMerge, setFullMerge] = useState(false);
  const [merging, setMerging] = useState(false);
  useEffect(() => {
    if (tags.length >= 2) {
      setDestination(tags[0].id);
    }
  }, [tags]);

  const resetData = () => {
    setFullMerge(false);
    setDestination(null);
    onClose();
  };

  const merge = async () => {
    const tab = [];
    tags.forEach((el) => {
      if (el.id !== destination) {
        tab.push(el.id);
      }
    });
    try {
      setMerging(true);
      await mergeTags({
        token,
        data: {
          destination,
          fullMerge,
          source: tab.join(),
        },
      });
      setMerging(false);
      queryClient.invalidateQueries(["fetchTags"]);
      if (afterMerge) afterMerge();
      resetData();
      toast.success(_("saved_successfully", language));
    } catch (error) {
      setMerging(false);
      console.error(error);
      toast.error(_("error_occurred", language));
    }
  };

  if (tags.length < 2) {
    return null;
  }
  return (
    <Modal
      className={styles.modal}
      title={""}
      open={modalOpen}
      onCancel={() => onClose()}
      footer={[]}
    >
      <p className={styles.question}>
        {_("merge_question", language)}{" "}
        {tags.length === 2 && (
          <>
            <strong>
              <em>
                {tags[0][nameAttr]}(#{tags[0].id})
              </em>
            </strong>{" "}
            {_("and", language)}{" "}
            <strong>
              <em>
                {tags[1][nameAttr]}(#{tags[1].id})
              </em>
            </strong>
          </>
        )}
      </p>

      <select
        value={destination}
        onChange={(e) => setDestination(parseInt(e.target.value))}
      >
        {tags.map((tag) => (
          <option value={tag.id}>
            {_("keep", language)} {tag[nameAttr]}(#{tag.id}){" "}
            {_("as_principle", language)}
          </option>
        ))}
      </select>

      <div className={styles.inputForm}>
        <input
          type="checkbox"
          name="check"
          id="check"
          value={fullMerge}
          onChange={() => setFullMerge(!fullMerge)}
        />
        <label htmlFor="check"> {_("merge_and_delete", language)} </label>
      </div>
      <div className={styles.actions}>
        <button className={styles.btn} onClick={() => resetData()}>
          {_("cancel", language)}
        </button>

        <button className={styles.btn} onClick={() => merge()}>
          {merging ? (
            <Loader
              style={{
                height: "10px",
              }}
              color={"rgb(80, 129, 190)"}
            />
          ) : (
            _("merge", language)
          )}
        </button>
      </div>
    </Modal>
  );
};

export default MergeModal;
