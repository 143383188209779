import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthorAvatar } from "tamtam-components";

import styles from "./Layout.module.scss";
import IconTamtam from "../icons/IconTamtam";
import { LANGUAGES } from "../../lib/consts";
import { setLanguage } from "../../store/auth";
import _ from "../../i18n";
import { logout } from "../../lib/utils";

const Header = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { user, language } = auth;

  const headlineAttr = `headline${language.charAt(0).toUpperCase() + language.slice(1)}`;

  return (
    <div className={styles.header}>
      <IconTamtam />
      <div className={styles.right}>
        {user ? (
          <div className={styles.userAvatar}>
            <div className={styles.avatar} style={{ backgroundImage: `url(${user.avatarUrl})` }}>
              {!user.avatarUrl && (
                <span>{[...user.firstName][0].toUpperCase() + [...user.lastName][0].toUpperCase()}</span>
              )}
            </div>
            <div className={styles.popup}>
              <AuthorAvatar
                author={{
                  name: `${user.firstName} ${user.lastName}`,
                  headline: user.author && user.author[headlineAttr] ? user.author[headlineAttr].title : "",
                  avatarUrl: user.avatarUrl,
                }}
                white={false}
              />
              <ul className={styles.languages}>
                {LANGUAGES.map((el) => (
                  <li
                    key={el}
                    className={language === el ? styles.active : ""}
                    onClick={() => dispatch(setLanguage(el))}
                  >
                    {el}
                  </li>
                ))}
              </ul>
              <span className={styles.logout} onClick={() => logout()}>
                {_("logout")}
              </span>
            </div>
          </div>
        ) : (
          <ul className={styles.languages}>
            {LANGUAGES.map((el) => (
              <li key={el} className={language === el ? styles.active : ""} onClick={() => dispatch(setLanguage(el))}>
                {el}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Header;
