import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";

import styles from "./HomePage.module.scss";
import _ from "../../i18n";
import IconFilter from "../icons/IconFilter";
import { cleanTags, deleteTag, fetchTags, saveSuperTag } from "../../api";
import IconCheck from "../icons/IconCheck";
import IconEdit from "../icons/IconEdit";
import IconDelete from "../icons/IconDelete";
import Pagination from "./Pagination";
import Filters from "./Filters";
import IconEye from "../icons/IconEye";
import SynonymsModal from "../common/SynonymsModal";
import MergeModal from "../common/MergeModal";
import IconCheck2 from "../icons/IconCheck2";
import VerificationModal from "../common/VerificationModal";
import CreateTagModal from "../common/CreateTagModal";
import { SELECT_STYLES } from "../../lib/consts";

const HomePage = () => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { language, token } = auth;

  const [search, setSearch] = useState("");
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [listTags, setListTags] = useState([]);
  const [nbResults, setNbResults] = useState(0);
  const [showFilters, setShowFilters] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [filters, setFilters] = useState({
    notHandled: false,
    notValidated: false,
    hasSynonyms: false,
    isSuperTag: false,
    hideSynonyms: false,
  });
  const [selectedTags, setSelectedTags] = useState([]);
  const [synonyms, setSynonyms] = useState(null);
  const [synonymsModalOpen, setSynonymsModalOpen] = useState(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [verificationTag, setVerificationTag] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [tagDelete, setTagDelete] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(null);
  const [tagEdit, setTagEdit] = useState(null);
  const [showSuperTagForm, setShowSuperTagForm] = useState(false);
  const [superTag, setSuperTag] = useState(null);

  useQuery(["fetchTags", token, start, limit, filters, search], async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await fetchTags({
          token,
          start,
          limit,
          ...filters,
          search,
        });
        setListTags(res.data.data);
        setNbResults(res.data.nbResult);
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false);
        console.error(error);
        if (error.response.data && error.response.data.statusCode === 404) {
          setListTags([]);
        }
      }
    }
  });

  const cleanTagsList = async () => {
    try {
      setIsFetching(true);
      await cleanTags({ token });
      queryClient.invalidateQueries(["fetchTags"]);
      toast.success(_("tags_cleaned_successfully", language));
    } catch (error) {
      toast.error(_("error_occurred", language));
      setIsFetching(false);
      console.error(error);
    }
  };

  const getNames = (tag) => {
    return (
      <ul className={styles.namesList}>
        {tag.nameFr && tag.nameFr.length > 0 && <li>{tag.nameFr}</li>}
        {tag.nameEn && tag.nameEn.length > 0 && <li>{tag.nameEn}</li>}
        {tag.nameNl && tag.nameNl.length > 0 && <li>{tag.nameNl}</li>}
      </ul>
    );
  };
  const getLanguages = (tag) => {
    return (
      <ul className={styles.namesList}>
        {tag.nameFr && tag.nameFr.length > 0 && <li>FR</li>}
        {tag.nameEn && tag.nameEn.length > 0 && <li>EN</li>}
        {tag.nameNl && tag.nameNl.length > 0 && <li>NL</li>}
      </ul>
    );
  };

  const paginationBtns = () => {
    const nbPages =
      parseInt(nbResults / limit) % 10
        ? parseInt(nbResults / limit) - 1
        : parseInt(nbResults / limit);
    const currentPage = start / limit;

    return (
      <ul className={styles.pagination}>
        <li>
          <button
            className={start - limit < 0 ? styles.disabled : ""}
            disabled={start - limit < 0}
            onClick={() => setStart(start - limit)}
          >
            {_("previous", language)}
          </button>
        </li>
        <li>
          <button
            className={currentPage === 0 ? styles.active : ""}
            onClick={() => setStart(0)}
          >
            1
          </button>
        </li>
        {currentPage < 4 ? (
          <>
            {[...Array(4)]
              .map((e, i) => {
                if (i + 1 < nbPages) {
                  return (
                    <li>
                      <button
                        className={currentPage === i + 1 ? styles.active : ""}
                        onClick={() => setStart((i + 1) * limit)}
                      >
                        {i + 2}
                      </button>
                    </li>
                  );
                }
                return null;
              })
              .filter(Boolean)}

            {nbPages > 4 && <li>...</li>}
          </>
        ) : nbPages >= 5 && currentPage > nbPages - 4 ? (
          <>
            <li>...</li>
            {[...Array(4)].map((e, i) => {
              return (
                <li>
                  <button
                    className={
                      currentPage === nbPages - (3 - i) - 1 ? styles.active : ""
                    }
                    onClick={() => setStart((nbPages - (3 - i) - 1) * limit)}
                  >
                    {nbPages - (3 - i)}
                  </button>
                </li>
              );
            })}
          </>
        ) : nbPages >= 5 && currentPage > 3 && currentPage < nbPages - 3 ? (
          <>
            <li>...</li>
            <li>
              <button onClick={() => setStart((currentPage - 1) * limit)}>
                {currentPage}
              </button>
            </li>
            <li>
              <button
                className={styles.active}
                onClick={() => setStart(currentPage * limit)}
              >
                {currentPage + 1}
              </button>
            </li>
            <li>
              <button onClick={() => setStart((currentPage + 1) * limit)}>
                {currentPage + 2}
              </button>
            </li>
            <li>...</li>
          </>
        ) : null}

        {nbPages >= 5 && (
          <li>
            <button
              className={currentPage === nbPages ? styles.active : ""}
              onClick={() => setStart(nbPages * limit)}
            >
              {nbPages + 1}
            </button>
          </li>
        )}
        <li>
          <button
            className={start + limit > nbPages * 10 ? styles.disabled : ""}
            disabled={start + limit > nbPages * 10}
            onClick={() => setStart(start + limit)}
          >
            {_("next", language)}
          </button>
        </li>
      </ul>
    );
  };

  const checkItem = (id) => {
    if (selectedTags.includes(id)) {
      setSelectedTags(selectedTags.filter((el) => el !== id));
    } else {
      setSelectedTags([...selectedTags, id]);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await deleteTag({ token, id: tagDelete });
      setListTags(listTags.filter((el) => el.id !== tagDelete));
      setTagDelete(null);
      setDeleteModalOpen(false);
      setDeleting(false);
      toast.success(_("deleted_successfully", language));
    } catch (error) {
      setDeleting(false);
      console.error(error);
      toast.error(_("error_occurred", language));
    }
  };

  const nameAttr = (tag, lngs = ["fr", "en", "nl"], lng = language) => {
    let val = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
    if (tag[val].length === 0 && lng.length > 0) {
      return nameAttr(
        tag,
        lngs.filter((el) => el !== lng),
        lngs[0]
      );
    }

    return tag[val];
  };

  const fetchSuperTags = (inputValue) => {
    return fetchTags({
      token,
      search: inputValue,
      isSuperTag: true,
    }).then((result) => {
      const tags = result.data.data;

      return tags.map((tag) => {
        let tmp = {
          label: nameAttr(tag),
          value: tag.id,
        };
        return tmp;
      });
    });
  };

  const associateToSuperTag = async () => {
    try {
      setIsFetching(true);
      for (let i = 0; i < selectedTags.length; i++) {
        const tagId = selectedTags[i];
        await saveSuperTag(token, { id: tagId, superTag: superTag.value });
      }
      queryClient.invalidateQueries(["fetchTags"]);
      toast.success(_("saved_successfully", language));
      setSuperTag(null);
      setShowSuperTagForm(false);
      setSelectedTags([]);
    } catch (error) {
      toast.error(_("error_occurred", language));
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedTags.length === 0) {
      setShowSuperTagForm(false);
      setSuperTag(null);
    }
  }, [selectedTags]);

  return (
    <div className={styles.homePage}>
      {isFetching && <div className={styles.fetchingMask}></div>}
      <div className={styles.title}>{_("tags_list", language)}</div>
      <div className={styles.actions_search}>
        <div className={styles.actions}>
          <button
            className={styles.btn}
            onClick={() => setShowFilters(!showFilters)}
          >
            <IconFilter />
            {_("filters", language)}
          </button>
          <button
            className={styles.btn}
            onClick={() => setCreateModalOpen(true)}
          >
            {_("add_tag", language)}
          </button>
          <button
            disabled={selectedTags.length < 2}
            className={classNames(
              styles.btn,
              selectedTags.length < 2 ? styles.disabled : ""
            )}
            onClick={() => setMergeModalOpen(true)}
          >
            {_("merge", language)}
          </button>
          <button className={styles.btn} onClick={() => cleanTagsList()}>
            {_("clean_tags", language)}
          </button>
          <button
            className={classNames(
              styles.btn,
              selectedTags.length === 0 ? styles.disabled : ""
            )}
            onClick={() => setShowSuperTagForm(true)}
            disabled={selectedTags.length === 0}
          >
            {_("associate_to_super_tag", language)}
          </button>
        </div>
        <input
          className={styles.search}
          type="text"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setStart(0);
          }}
          placeholder={_("search_tag", language)}
        />
      </div>

      {showSuperTagForm && (
        <div className={styles.associate}>
          <span>{_("associate_to_super_tag", language)}</span>
          <div className={styles.select_container}>
            <AsyncSelect
              cacheOptions
              isClearable
              value={superTag}
              styles={SELECT_STYLES}
              onChange={(e) => setSuperTag(e)}
              loadOptions={fetchSuperTags}
              placeholder={_("select_super_tag", language)}
              classNamePrefix="react-select"
            />
          </div>
          <button className={styles.btn} onClick={() => associateToSuperTag()}>
            {_("save", language)}
          </button>
          <button
            className={styles.btn}
            onClick={() => {
              setSuperTag(null);
              setSelectedTags([]);
              setShowSuperTagForm(false);
            }}
          >
            {_("cancel", language)}
          </button>
        </div>
      )}

      {showFilters && (
        <Filters
          setShowFilters={setShowFilters}
          filters={filters}
          setFilters={setFilters}
          setStart={setStart}
        />
      )}

      <div className={styles.table_data}>
        <select
          className={styles.limit}
          value={limit}
          onChange={(e) => setLimit(parseInt(e.target.value))}
        >
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={500}>500</option>
        </select>

        <table className={styles.tags_table}>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>{_("name", language)}</th>
              <th>{_("handled", language)}</th>
              <th>{_("usage_counter", language)}</th>
              <th>{_("synonyms", language)}</th>
              <th>{_("state", language)}</th>
              <th>{_("languages", language)}</th>
              <th>{_("actions", language)}</th>
            </tr>
          </thead>
          <tbody>
            {listTags.length > 0 &&
              listTags.map((tag) => (
                <tr key={tag.id}>
                  <td className={styles.cell}>
                    <input
                      type="checkbox"
                      checked={selectedTags.includes(tag.id)}
                      onChange={() => checkItem(tag.id)}
                    />
                  </td>
                  <td className={styles.cell}>{`#${tag.id}`}</td>
                  <td className={styles.cell}>{getNames(tag)}</td>
                  <td className={styles.cell}>
                    {tag.isHandled ? <IconCheck /> : ""}
                  </td>
                  <td className={styles.cell}>{tag.counter}</td>
                  <td className={styles.cell}>
                    {tag.synonyms && (
                      <button
                        className={styles.synonym_btn}
                        onClick={() => {
                          setSynonyms(tag.synonyms);
                          setSynonymsModalOpen(true);
                        }}
                      >
                        <IconEye />
                      </button>
                    )}
                  </td>
                  <td className={styles.cell}>{_(`${tag.state}`)}</td>
                  <td className={styles.cell}>{getLanguages(tag)}</td>
                  <td className={styles.cell}>
                    <IconEdit
                      onClick={() => {
                        setTagEdit(tag);
                        setCreateModalOpen(true);
                      }}
                    />
                    <IconDelete
                      onClick={() => {
                        setTagDelete(tag.id);
                        setDeleteModalOpen(true);
                      }}
                    />
                    {tag.state === "TO_VALIDATE" && (
                      <IconCheck2
                        onClick={() => {
                          setVerificationTag(tag);
                          setVerificationModalOpen(true);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!isFetching && listTags.length === 0 && (
          <div className={styles.not_found}>
            {_("no_data_available_in_table", language)}
          </div>
        )}
        {listTags.length > 0 && (
          <Pagination
            start={start}
            list={listTags}
            nbResults={nbResults}
            paginationBtns={paginationBtns}
          />
        )}
      </div>
      <SynonymsModal
        synonyms={synonyms}
        modalOpen={synonymsModalOpen}
        onClose={() => setSynonymsModalOpen(false)}
      />
      <MergeModal
        modalOpen={mergeModalOpen}
        onClose={() => {
          setSelectedTags([]);
          setMergeModalOpen(false);
        }}
        tags={listTags.filter((el) => selectedTags.includes(el.id))}
      />
      <VerificationModal
        modalOpen={verificationModalOpen}
        onClose={() => {
          setVerificationTag(null);
          setVerificationModalOpen(false);
        }}
        tag={verificationTag}
      />
      <ModalConfirm
        type="delete"
        isOpen={deleteModalOpen}
        inProcess={deleting}
        onCancel={() => {
          setTagDelete(null);
          setDeleteModalOpen(false);
        }}
        onConfirm={() => handleDelete()}
        title={"Confirmation de suppression"}
        text={"Êtes-vous sûr de bien vouloir supprimer cet élément ?"}
        labelNo={"Non"}
        labelYes={"Oui"}
      />
      <CreateTagModal
        modalOpen={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          setTagEdit(null);
        }}
        currentValue={tagEdit}
      />
    </div>
  );
};

export default HomePage;
