const en = {
  welcome: "Welcome",
  logout: "Logout",
  login: "Login",
  tags_list: "Tags list",
  filters: "Filters",
  add_tag: "Add Tag",
  previous: "Previous",
  next: "Next",
  merge: "Merge",
  clean_tags: "Clean Tags",
  search_tag: "Search Tag",
  name: "Name",
  handled: "Handled",
  usage_counter: "Usage Counter",
  synonyms: "Synonyms",
  state: "State",
  languages: "Languages",
  actions: "Actions",
  no_data_available_in_table: "No data available in table",
  not_handled: "Not Handled",
  not_validated: "Not Validated",
  has_synonyms: "Has Synonyms",
  showing: "Showing",
  to: "to",
  of: "of",
  name_required: "Name Required",
  tag_exists: "Tag Exists",
  update_tag: "Update Tag",
  translate: "Translate",
  name_fr: "Name Fr",
  name_en: "Name En",
  name_nl: "Name Nl",
  synonyms_list: "Synonyms List",
  add_synonym: "Add Synonym",
  remove: "Remove",
  TO_VALIDATE: "To Validate",
  TREATED: "Treated",
  UNTREATED: "Untreated",
  is_synonym_of: "Is synonym of",
  is_abbreviation: "Is Abbreviation",
  is_universal: "Is Universal",
  cancel: "Cancel",
  save: "Save",
  merge_question: "Are you sure you want to merge",
  and: "and",
  keep: "Keep",
  as_principle: "as the principal tag",
  merge_and_delete: "Merge and delete",
  similars_tag_list: "Similars tag list",
  similars_fr: "Similars Fr",
  similars_en: "Similars En",
  similars_nl: "Similars Nl",
  saved_successfully: "Saved successfully",
  error_occurred: "An error has occurred",
  deleted_successfully: "Deleted successfully",
  tags_cleaned_successfully: "Tags cleaned successfully",
  is_super_tag: "Is super tag",
  theme: "Theme",
  pages: "Pages",
  select_theme: "Select theme",
  select_page: "Select page",
  associate_to_super_tag: "Associate to a super tag",
  select_super_tag: "Select a super tag",
  hide_synonyms: "Hide synonyms",
};
export default en;
