import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "./Header";
import { authHasExpired, getAuthInfosFromCookie, logout } from "../../lib/utils";
import { setAuthToken, setAuthUser, setExpiresIn, setLanguage, setTokenCreatedAt } from "../../store/auth";
import { fetchTTPUser } from "../../api";
import styles from "./Layout.module.scss";
import AppLoader from "../common/AppLoader";

const Layout = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const f = async () => {
      let authInfos = getAuthInfosFromCookie();

      dispatch(setLanguage(localStorage.getItem("lng") ? localStorage.getItem("lng") : "fr"));

      if (!authInfos || authHasExpired(authInfos)) {
        if (location.pathname === "/login") {
          setIsLoading(false);
        } else {
          logout(location.pathname !== "/login");
        }
      }

      if (auth && auth.user && authInfos.token === auth.token) {
        dispatch(setAuthToken(auth.token));
        dispatch(setExpiresIn(auth.expiresIn));
        dispatch(setTokenCreatedAt(auth.createdAt));
        dispatch(setAuthUser(auth.user));
        navigate("/");
        setIsLoading(false);
      } else if (authInfos) {
        dispatch(setAuthToken(authInfos.token));
        dispatch(setExpiresIn(authInfos.expiresIn));
        dispatch(setTokenCreatedAt(authInfos.createdAt));

        try {
          const res = await fetchTTPUser({ userId: authInfos.id, token: authInfos.token });
          if (!["ADMIN", "ADMIN_SUPERVISOR"].includes(res.data.data[0].type)) {
            logout();
          } else {
            dispatch(setAuthUser(res.data.data[0]));
            navigate("/");
          }
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          logout(location.pathname !== "/login");
          setIsLoading(false);
        }
      }
    };
    f();
  }, []);

  return (
    <div className={styles.layout}>
      {loading ? (
        <AppLoader />
      ) : (
        <>
          <Header />
          <Outlet />
        </>
      )}
    </div>
  );
};

export default Layout;
