export const LANGUAGES = ["fr", "en", "nl"];

export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const TTP_API_URL = process.env.REACT_APP_TTP_API_URL;
export const TTP_HOME_URL = process.env.REACT_APP_TTP_HOME_URL;
export const TTP_TAGS_URL = process.env.REACT_APP_TTP_TAGS_URL;

export const AUTH_COOKIE_NAME = `ttp_auth_${APP_ENV}`;

export const SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #B2BCC6",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.color ? data.color : "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    textTransform: "uppercase",
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
};
