import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  expiresIn: null,
  createdAt: null,
  user: null,
  language: "fr",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setExpiresIn: (state, action) => {
      state.expiresIn = action.payload;
    },
    setTokenCreatedAt: (state, action) => {
      state.createdAt = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setAuthUser, setAuthToken, setExpiresIn, setTokenCreatedAt, setLanguage } = authSlice.actions;

export default authSlice.reducer;
