import axios from "axios";
import { TTP_API_URL } from "../lib/consts";

export const fetchTTPUser = ({ userId, token }) => {
  const filter = [
    {
      property: "id",
      value: userId,
      operator: "eq",
    },
  ];
  const fields = ["*", "avatar", "author"];
  const requestUrl = `${TTP_API_URL}/organization/user`;

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
  };

  return axios.get(requestUrl, { params });
};
