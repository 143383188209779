import React from "react";

const IconFilter = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
      <path
        d='M410.001-250.001v-59.998h139.998v59.998H410.001Zm-160-207.693v-59.998h459.998v59.998H250.001Zm-120-207.692v-59.999h699.998v59.999H130.001Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IconFilter;
