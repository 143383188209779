const nl = {
  welcome: "Welkom",
  logout: "Uitloggen",
  login: "Log in",
  tags_list: "Lijst met tags",
  filters: "Filters",
  add_tag: "Tag toevoegen",
  previous: "Vorige",
  next: "Volgende",
  merge: "Samenvoegen",
  clean_tags: "Tags opschonen",
  search_tag: "Zoeklabel",
  name: "naam",
  handled: "Behandeld",
  usage_counter: "Gebruiksteller",
  synonyms: "Synoniemen",
  state: "Staat",
  languages: "Talen",
  actions: "Acties",
  no_data_available_in_table: "Geen gegevens beschikbaar in tabel",
  not_handled: "Niet afgehandeld",
  not_validated: "Niet gevalideerd",
  has_synonyms: "Heeft synoniemen",
  showing: "Toon",
  to: "naar",
  of: "van",
  name_required: "Naam vereist",
  tag_exists: "Tag bestaat",
  update_tag: "Tag bijwerken",
  translate: "Vertalen",
  name_fr: "Naam Fr",
  name_en: "Naam En",
  name_nl: "Naam Nl",
  synonyms_list: "Synoniemenlijst",
  add_synonym: "Synoniem toevoegen",
  remove: "Verwijderen",
  TO_VALIDATE: "Om te valideren",
  TREATED: "Behandeld",
  UNTREATED: "Onbehandeld",
  is_synonym_of: "Is synoniem van",
  is_abbreviation: "Is afkorting",
  is_universal: "Is universeel",
  cancel: "Annuleren",
  save: "Opslaan",
  merge_question: "Weet je zeker dat je wilt samenvoegen",
  and: "en",
  keep: "Bewaren",
  as_principle: "als de belangrijkste tag",
  merge_and_delete: "Samenvoegen en verwijderen",
  similars_tag_list: "Similars taglijst",
  similars_fr: "Similars Fr",
  similars_en: "Similars Nl",
  similars_nl: "Similars Nl",
  saved_successfully: "Succesvol opgeslagen",
  error_occurred: "Er is een fout opgetreden",
  deleted_successfully: "Succesvol verwijderd",
  tags_cleaned_successfully: "Tags succesvol opgeschoond",
  is_super_tag: "Is een supertag",
  theme: "Thema",
  pages: "Pagina's",
  select_theme: "Selecteer thema",
  select_page: "Selecteer pagina",
  associate_to_super_tag: "Koppelen aan een supertag",
  select_super_tag: "Selecteer een supertag",
  hide_synonyms: "Verberg synoniemen",
};
export default nl;
