import React, { useState } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import styles from "./VerificationModal.module.scss";
import _ from "../../../i18n";
import { fetchTags, validateTag } from "../../../api";
import Loader from "../Loader";
import IconEdit from "../../icons/IconEdit";
import IconDelete from "../../icons/IconDelete";
import classNames from "classnames";
import MergeModal from "../MergeModal";

const VerificationModal = ({ modalOpen, onClose, tag }) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { language, token } = auth;
  const [isFetching, setIsFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedTab, setSelectedTab] = useState("similarsFr");
  const [selectedTags, setSelectedTags] = useState([]);
  const [openMergeModal, setOpenMergeModal] = useState(false);

  const { data } = useQuery(["fetchSimilars", token, tag], async () => {
    if (token && tag) {
      try {
        setIsFetching(true);

        const res = await fetchTags({
          token,
          similarsFr: tag.similarsFr,
          similarsEn: tag.similarsEn,
          similarsNl: tag.similarsNl,
        });
        setIsFetching(false);
        return res.data.data;
      } catch (error) {
        setIsFetching(false);
        console.error(error);
      }
    }
  });

  const getNames = (tag) => {
    return (
      <ul className={styles.namesList}>
        {tag.nameFr && tag.nameFr.length > 0 && <li>{tag.nameFr}</li>}
        {tag.nameEn && tag.nameEn.length > 0 && <li>{tag.nameEn}</li>}
        {tag.nameNl && tag.nameNl.length > 0 && <li>{tag.nameNl}</li>}
      </ul>
    );
  };
  const checkItem = (id) => {
    if (selectedTags.includes(id)) {
      setSelectedTags(selectedTags.filter((el) => el !== id));
    } else {
      setSelectedTags([...selectedTags, id]);
    }
  };
  const reset = () => {
    setSelectedTab("similarsFr");
    setSelectedTags([]);
    onClose();
  };

  const validate = async () => {
    try {
      setSaving(true);
      await validateTag({ token, id: tag.id });
      queryClient.invalidateQueries(["fetchTags"]);
      setSaving(false);
      reset();
      toast.success(_("saved_successfully", language));
    } catch (error) {
      setSaving(false);
      console.error(error);
      toast.error(_("error_occurred", language));
    }
  };
  const afterMerge = () => {
    queryClient.invalidateQueries(["fetchSimilars"]);
  };
  if (!tag) {
    return null;
  }
  return (
    <Modal
      style={{ top: 20 }}
      className={styles.modal}
      title={_("similars_tag_list", language)}
      open={modalOpen}
      onCancel={() => reset()}
      footer={[]}
    >
      <div className={styles.content}>
        {isFetching && <div className={styles.fetchingMask}></div>}
        <div className={styles.top_actions}>
          <button
            className={classNames(styles.btn, selectedTab === "similarsFr" ? styles.active : "")}
            onClick={() => setSelectedTab("similarsFr")}
          >
            {_("similars_fr", language)}
          </button>
          <button
            className={classNames(styles.btn, selectedTab === "similarsEn" ? styles.active : "")}
            onClick={() => setSelectedTab("similarsEn")}
          >
            {_("similars_en", language)}
          </button>
          <button
            className={classNames(styles.btn, selectedTab === "similarsNl" ? styles.active : "")}
            onClick={() => setSelectedTab("similarsNl")}
          >
            {_("similars_nl", language)}
          </button>
          <button
            disabled={selectedTags.length !== 2}
            className={classNames(styles.btn, selectedTags.length !== 2 ? styles.disabled : "")}
            onClick={() => setOpenMergeModal(true)}
          >
            {_("merge", language)}
          </button>
        </div>
        <table className={styles.table}>
          <thead>
            <th></th>
            <th></th>
            <th>{_("name", language)}</th>
            <th>{_("usage_counter", language)}</th>
            <th>{_("actions", language)}</th>
          </thead>
          <tbody className={styles.scrollable}>
            {data &&
              data[selectedTab] &&
              data[selectedTab].length > 0 &&
              data[selectedTab].map((tag) => (
                <tr key={tag.id}>
                  <td className={styles.cell}>
                    <input type='checkbox' checked={selectedTags.includes(tag.id)} onChange={() => checkItem(tag.id)} />
                  </td>
                  <td className={styles.cell}>#{tag.id}</td>
                  <td className={styles.cell}>{getNames(tag)}</td>
                  <td className={styles.cell}>{tag.counter}</td>
                  <td className={styles.cell}>
                    <IconEdit />
                    <IconDelete />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {!isFetching && data && ((data[selectedTab] && data[selectedTab].length === 0) || !data[selectedTab]) && (
          <div className={styles.not_found}>{_("no_data_available_in_table", language)}</div>
        )}
        <div className={styles.bottom_actions}>
          <button className={styles.btn} onClick={() => reset()}>
            {_("cancel", language)}
          </button>
          <button className={styles.btn} onClick={() => validate()}>
            {saving ? (
              <Loader
                style={{
                  height: "10px",
                }}
                color={"rgb(80, 129, 190)"}
              />
            ) : (
              _("save", language)
            )}
          </button>
        </div>
        <MergeModal
          modalOpen={openMergeModal}
          onClose={() => {
            setSelectedTags([]);
            setOpenMergeModal(false);
          }}
          tags={data && data[selectedTab] ? data[selectedTab].filter((el) => selectedTags.includes(el.id)) : []}
          afterMerge={afterMerge}
        />
      </div>
    </Modal>
  );
};

export default VerificationModal;
