import React from "react";
import { useSelector } from "react-redux";

import styles from "./HomePage.module.scss";
import IconReload from "../icons/IconReload";
import IconClose from "../icons/IconClose";
import _ from "../../i18n";

const Filters = ({ setShowFilters, filters, setFilters, setStart }) => {
  const language = useSelector((state) => state.auth.language);

  return (
    <div className={styles.filters_container}>
      <div
        className={styles.actions}
        onClick={() =>
          setFilters({
            notHandled: false,
            notValidated: false,
            hasSynonyms: false,
            isSuperTag: false,
            hideSynonyms: false,
          })
        }
      >
        <div>
          <IconReload />
        </div>
        <div className={styles.close} onClick={() => setShowFilters(false)}>
          <IconClose />
        </div>
      </div>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <input
            type="checkbox"
            name="filter_4"
            id="filter_4"
            onChange={() => {
              setFilters({ ...filters, isSuperTag: !filters.isSuperTag });
              setStart(0);
            }}
            checked={filters.isSuperTag}
          />
          <label htmlFor="filter_4">{_("is_super_tag", language)}</label>
        </div>
        <div className={styles.filter}>
          <input
            type="checkbox"
            name="filter_5"
            id="filter_5"
            onChange={() => {
              setFilters({ ...filters, hideSynonyms: !filters.hideSynonyms });
              setStart(0);
            }}
            checked={filters.hideSynonyms}
          />
          <label htmlFor="filter_5">{_("hide_synonyms", language)}</label>
        </div>
        <div className={styles.filter}>
          <input
            type="checkbox"
            name="filter_3"
            id="filter_3"
            onChange={() => {
              setFilters({ ...filters, hasSynonyms: !filters.hasSynonyms });
              setStart(0);
            }}
            checked={filters.hasSynonyms}
          />
          <label htmlFor="filter_3">{_("has_synonyms", language)}</label>
        </div>
        <div className={styles.filter}>
          <input
            type="checkbox"
            name="filter_1"
            id="filter_1"
            onChange={() => {
              setFilters({ ...filters, notHandled: !filters.notHandled });
              setStart(0);
            }}
            checked={filters.notHandled}
          />
          <label htmlFor="filter_1">{_("not_handled", language)}</label>
        </div>
        <div className={styles.filter}>
          <input
            type="checkbox"
            name="filter_2"
            id="filter_2"
            onChange={() => {
              setFilters({ ...filters, notValidated: !filters.notValidated });
              setStart(0);
            }}
            checked={filters.notValidated}
          />
          <label htmlFor="filter_2">{_("not_validated", language)}</label>
        </div>
      </div>
    </div>
  );
};

export default Filters;
