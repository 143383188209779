import React from "react";
import styles from "./LoginPage.module.scss";
import { TTP_HOME_URL, TTP_TAGS_URL } from "../../lib/consts";
import _ from "../../i18n";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const language = useSelector((state) => state.auth.language);
  return (
    <div className={styles.loginPage} style={{ backgroundImage: `url("/img/tagbg.webp")` }}>
      <div className={styles.content}>
        <h1>
          {_("welcome", language)} ! <br /> Tamtam Tags Manager
        </h1>
        <a href={`${TTP_HOME_URL}/?goto=${TTP_TAGS_URL}`} className={styles.button}>
          {_("login", language)}
        </a>
      </div>
    </div>
  );
};

export default LoginPage;
