import { APP_ENV, AUTH_COOKIE_NAME, TTP_TAGS_URL } from "./consts";
import axios from "axios";

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function setCookie(name, value, expires, path, domain, secure) {
  document.cookie =
    name +
    " = " +
    encodeURIComponent(value) +
    "  " +
    (!expires ? "" : "; expires = " + expires.toUTCString()) +
    (!path ? "" : "; path = " + path) +
    (!domain ? "" : "; domain = " + domain) +
    (secure === true ? "; secure" : "");
}

export const getAuthInfosFromCookie = () => {
  const cookie = getCookie(AUTH_COOKIE_NAME);

  if (!cookie) {
    return null;
  }

  return JSON.parse(cookie);
};

export const authHasExpired = (authInfos) => {
  const now = Math.floor(new Date().getTime() / 1000);

  return (
    !authInfos ||
    typeof authInfos !== "object" ||
    !authInfos.createdAt ||
    !authInfos.expiresIn ||
    now >= parseFloat(authInfos.createdAt) + parseFloat(authInfos.expiresIn)
  );
};

export const logout = (redirect = true) => {
  let dtExpire = new Date();
  dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

  setCookie(`ttp_auth_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");
  setCookie("ttp_community_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
  setCookie("ttp_lang_" + APP_ENV, "", dtExpire, "/", "tamtam.pro");
  setCookie("XSRF-TOKEN", "", dtExpire, "/", "tamtam.pro");
  setCookie(`ttp_session_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");

  if (redirect) {
    window.location.assign(`${TTP_TAGS_URL}/login`);
  }
};

// axios

export function generateCancellationTokenSource() {
  let cancellationToken = axios.CancelToken;
  return cancellationToken.source();
}

export function getRequestCancellationToken(cancellationTokenSource, newCancellationTokenSource) {
  if (cancellationTokenSource) {
    cancellationTokenSource.cancel("Request canceled!");
  }
  cancellationTokenSource = newCancellationTokenSource;
  if (cancellationTokenSource) {
    return cancellationTokenSource.token;
  }
  return null;
}

export function getRequestConfig(params, requestCancellationToken) {
  let requestConfig = {};

  if (params) {
    requestConfig.params = params;
  }

  if (requestCancellationToken) {
    requestConfig.cancelToken = requestCancellationToken;
  }

  return requestConfig;
}

export function throwCatchedError(thrown) {
  if (axios.isCancel(thrown)) {
    throw { response: { status: 700 } };
  } else {
    throw thrown;
  }
}
