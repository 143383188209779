import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Layout from "./components/Layout";
import LoginPage from "./components/LoginPage";
import HomePage from "./components/HomePage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/' element={<HomePage />} />
          </Route>
        </Routes>
        <ToastContainer autoClose={3000} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
