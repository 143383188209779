import React from "react";

const IconTamtam = () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='174px'
      height='163px'
      viewBox='-7.134 -11.563 174 163'
      overflow='visible'
      enable-background='new -7.134 -11.563 174 163'
    >
      <defs></defs>
      <circle fill-rule='evenodd' clip-rule='evenodd' fill='#F49649' cx='70.866' cy='72.687' r='14.173' />
      <circle
        fill-rule='evenodd'
        clip-rule='evenodd'
        fill='none'
        stroke='#5081BE'
        stroke-width='14.1732'
        stroke-miterlimit='10'
        cx='70.866'
        cy='72.687'
        r='35.433'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        fill='none'
        stroke='#7F8080'
        stroke-width='14.1732'
        stroke-miterlimit='10'
        d='  M81.982,135.5c-3.61,0.635-7.325,0.966-11.116,0.966c-35.225,0-63.779-28.555-63.779-63.779c0-24.922,14.295-46.506,35.133-57'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        fill='none'
        stroke='#7F8080'
        stroke-width='5.6693'
        stroke-miterlimit='10'
        d='  M106.572,25.052c14.464,10.859,23.821,28.154,23.821,47.635c0,4.691-0.543,9.256-1.569,13.635'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        fill='none'
        stroke='#7F8080'
        stroke-width='5.6693'
        stroke-miterlimit='10'
        d='  M115.01,13.663c17.945,13.442,29.557,34.877,29.557,59.024c0,5.76-0.661,11.364-1.91,16.743'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        fill='none'
        stroke='#7F8080'
        stroke-width='5.6693'
        stroke-miterlimit='10'
        d='  M123.442,2.27c21.428,16.025,35.298,41.601,35.298,70.417c0,6.827-0.778,13.472-2.252,19.851'
      />
    </svg>
  );
};

export default IconTamtam;
