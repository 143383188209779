import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

import styles from "./SynonymsModal.module.scss";
import _ from "../../../i18n";

const SynonymsModal = ({ modalOpen, onClose, synonyms }) => {
  const language = useSelector((state) => state.auth.language);
  if (!synonyms) {
    return null;
  }
  return (
    <Modal
      style={{ top: 20 }}
      className={styles.modal}
      title={_("synonyms_list", language)}
      open={modalOpen}
      onCancel={() => onClose()}
      footer={[]}
    >
      <div className={styles.content}>
        <table className={styles.table}>
          <thead>
            <th></th>
            <th>{_("name_fr", language)}</th>
            <th>{_("name_en", language)}</th>
            <th>{_("name_nl", language)}</th>
          </thead>
          <tbody>
            {synonyms.map((tag) => (
              <tr key={tag.id}>
                <td className={styles.cell}>#{tag.id}</td>
                <td className={styles.cell}>{tag.nameFr}</td>
                <td className={styles.cell}>{tag.nameEn}</td>
                <td className={styles.cell}>{tag.nameNl}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default SynonymsModal;
