import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import styles from "./CreateTagModal.module.scss";
import _ from "../../../i18n";
import IconAdd from "../../icons/IconAdd";
import IconDelete from "../../icons/IconDelete";
import { useQuery, useQueryClient } from "react-query";
import {
  fetchTags,
  getPages,
  getSynonyms,
  getThemesList,
  saveTag,
  translateTag,
} from "../../../api";
import Loader from "../Loader";
import IconClose from "../../icons/IconClose";
import { SELECT_STYLES } from "../../../lib/consts";

const CreateTagModal = ({ modalOpen, onClose, currentValue }) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { language, token } = auth;
  const statusOptions = [
    { value: "0", label: _("not_handled", language) },
    { value: "1", label: _("handled", language) },
  ];
  const stateOptions = [
    { value: "TO_VALIDATE", label: _("TO_VALIDATE", language) },
    { value: "TREATED", label: _("TREATED", language) },
    { value: "UNTREATED", label: _("UNTREATED", language) },
  ];
  const [synonyms, setSynonyms] = useState([]);
  const [search, setSearch] = useState("");
  const [synonymOf, setSynonymOf] = useState(null);
  const [status, setStatus] = useState(statusOptions[0]);
  const [state, setState] = useState(stateOptions[0]);
  const [isAbbreviation, setIsAbbreviation] = useState(false);
  const [isUniversal, setIsUniversal] = useState(false);
  const [isSuperTag, setIsSuperTag] = useState(false);
  const [name, setName] = useState({ fr: "", en: "", nl: "" });
  const [nameErr, setNameErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [bannerErr, setBannerErr] = useState("");
  const [theme, setTheme] = useState(null);
  const [pages, setPages] = useState([]);
  const [startThemes, setStartThemes] = useState(0);
  const [themes, setThemes] = useState([]);
  const [superTag, setSuperTag] = useState(null);

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  useEffect(() => {
    if (currentValue) {
      setName({
        fr: currentValue.nameFr,
        en: currentValue.nameEn,
        nl: currentValue.nameNl,
      });
      setSynonymOf(currentValue.parent ? currentValue.parent : null);
      setStatus(statusOptions.filter((el) => el.value === currentValue.status));
      setState(stateOptions.filter((el) => el.value === currentValue.state));
      setIsAbbreviation(currentValue.isAbbreviation);
      setIsUniversal(currentValue.isUniversal);
      setIsSuperTag(currentValue.isSuperTag);
      setSuperTag(
        currentValue.superTag
          ? {
              label: nameAttr(currentValue.superTag),
              value: currentValue.superTag.id,
            }
          : null
      );
      setSynonyms(
        currentValue.synonyms
          ? currentValue.synonyms.map((el) => {
              return {
                id: 0,
                uid: uuidv4(),
                nameFr: el.nameFr,
                nameEn: el.nameEn,
                nameNl: el.nameNl,
              };
            })
          : []
      );
      if (currentValue.theme) {
        getThemesList({ token, id: currentValue.theme }).then((res) => {
          const tmp = res.data.data[0];
          setTheme({
            id: tmp.id,
            title:
              tmp[titleAttr] ||
              tmp["titleFr"] ||
              tmp["titleEn"] ||
              tmp["titleNl"],
          });
        });
      }
      if (currentValue.pages) {
        getPages({ token, ids: currentValue.pages }).then((res) => {
          const list = res.data.data;
          const tmp = [];
          list.forEach((element) => {
            tmp.push({
              id: element.id,
              title:
                element[titleAttr] ||
                element["titleFr"] ||
                element["titleEn"] ||
                element["titleNl"],
            });
          });
          setPages(tmp);
        });
      }
    }
  }, [currentValue]);

  const nameAttr = (tag, lngs = ["fr", "en", "nl"], lng = language) => {
    let val = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
    if (tag[val].length === 0 && lng.length > 0) {
      return nameAttr(
        tag,
        lngs.filter((el) => el !== lng),
        lngs[0]
      );
    }

    return tag[val];
  };

  const { data, isLoading } = useQuery(
    ["searchTags", token, search],
    async () => {
      if (token && search.length > 0) {
        try {
          const res = await fetchTags({ token, search });
          return res.data.data;
        } catch (error) {
          console.error(error);
        }
      }
    }
  );

  const { isFetching: loadingThemes } = useQuery(
    ["getThemes", token, startThemes],
    async () => {
      if (token) {
        try {
          const res = await getThemesList({ token, start: startThemes });
          let themeOptions = [];
          res.data.data.map((theme, index) => {
            const themeTitle =
              theme[titleAttr] ||
              theme["titleFr"] ||
              theme["titleEn"] ||
              theme["titleNl"];
            themeOptions.push({
              id: theme.id,
              title: themeTitle,
            });
          });
          setThemes([...themes, ...themeOptions]);
        } catch (error) {
          console.error(error);
        }
      }
    }
  );

  const { data: pagesOptions, isFetching: loadingPages } = useQuery(
    ["getPages", token, theme],
    async () => {
      if (token && theme) {
        try {
          const res = await getPages({ token, theme: theme.id });
          const tmp = res.data.data.map((page) => {
            const pageTitle =
              page[titleAttr] ||
              page["titleFr"] ||
              page["titleEn"] ||
              page["titleNl"];
            return {
              id: page.id,
              title: pageTitle,
            };
          });

          return tmp;
        } catch (error) {
          console.error(error);
        }
      }
    }
  );

  const addSynonym = () => {
    setSynonyms([
      ...synonyms,
      { id: 0, uid: uuidv4(), nameFr: "", nameEn: "", nameNl: "" },
    ]);
  };

  const synonymChange = (uid, value, nameLng) => {
    setSynonyms(
      synonyms.map((el) => {
        if (el.uid === uid) {
          return {
            ...el,
            [nameLng]: value,
          };
        }
        return el;
      })
    );
  };

  const translate = async () => {
    try {
      setLoading(true);
      const res = await translateTag({
        token,
        text:
          name.fr.trim().length > 0
            ? name.fr
            : name.en.trim().length > 0
            ? name.en
            : name.nl,
        lng:
          name.fr.trim().length > 0
            ? "fr"
            : name.en.trim().length > 0
            ? "en"
            : "nl",
      });

      setName(Object.assign({}, ...res.data.data.flat()));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const fetchSynonyms = async () => {
    try {
      setLoading(true);
      const res = await getSynonyms({
        token,
        text:
          name.fr.trim().length > 0
            ? name.fr
            : name.en.trim().length > 0
            ? name.en
            : name.nl,
      });
      if (res.data.data.fr.length > 0) {
        let syn = [];
        for (let index = 0; index < res.data.data.fr.length; index++) {
          syn.push({
            id: 0,
            uid: uuidv4(),
            nameFr: res.data.data.fr[index] ? res.data.data.fr[index] : "",
            nameEn: res.data.data.en[index] ? res.data.data.en[index] : "",
            nameNl: res.data.data.nl[index] ? res.data.data.nl[index] : "",
          });
        }
        setSynonyms([...synonyms, ...syn]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const resetData = () => {
    setSynonyms([]);
    setSearch("");
    setSynonymOf(null);
    setStatus(statusOptions[0]);
    setState(stateOptions[0]);
    setIsAbbreviation(false);
    setIsUniversal(false);
    setIsSuperTag(false);
    setName({ fr: "", en: "", nl: "" });
    setNameErr("");
    setTheme(null);
    setPages([]);
    setSuperTag(null);
    onClose();
  };

  const handleSave = async () => {
    if (
      name.fr.trim().length === 0 &&
      name.en.trim().length === 0 &&
      name.nl.trim().length === 0
    ) {
      setNameErr("name_required");
      return;
    } else {
      setNameErr("");
    }
    setBannerErr("");
    try {
      let data = {
        id: currentValue ? currentValue.id : null,
        nameFr: name.fr,
        nameEn: name.en,
        nameNl: name.nl,
        isSynonymOf: synonymOf ? synonymOf.id : null,
        status: status.value,
        state: state.value,
        synonyms: synonyms.map((el) => {
          return {
            id: el.id,
            nameFr: el.nameFr,
            nameEn: el.nameEn,
            nameNl: el.nameNl,
          };
        }),
        isHandled: status === "1",
        isAbbreviation,
        isUniversal,
        pages,
        isSuperTag,
        theme: theme ? theme.id : null,
        superTag: superTag ? superTag.value : null,
      };

      setLoading(true);
      await saveTag({ token, data });
      setLoading(false);
      queryClient.invalidateQueries(["fetchTags"]);
      resetData();
      toast.success(_("saved_successfully", language));
    } catch (error) {
      setLoading(false);
      if (error.response.data.errors[0] === "Tag already exists") {
        setBannerErr("tag_exists");
      } else {
        toast.error(_("error_occurred", language));
      }
      console.error(error.response.data.errors);
    }
  };

  const customFilter = (option, searchText) => {
    return option.data.title.toLowerCase().includes(searchText.toLowerCase());
  };

  const handleChangeTheme = (theme) => {
    setTheme(theme);
    setPages([]);
  };
  const fetchSuperTags = (inputValue) => {
    return fetchTags({
      token,
      search: inputValue,
      isSuperTag: true,
    }).then((result) => {
      const tags = result.data.data;

      return tags.map((tag) => {
        let tmp = {
          label: nameAttr(tag),
          value: tag.id,
        };
        return tmp;
      });
    });
  };

  return (
    <Modal
      style={{ top: 10 }}
      className={styles.modal}
      title={_(currentValue ? "update_tag" : "add_tag", language)}
      open={modalOpen}
      onCancel={() => resetData()}
      footer={[]}
    >
      <div className={styles.content}>
        {loading && <div className={styles.loadingCover}></div>}
        <div className={styles.actions}>
          <button className={styles.btn} onClick={() => translate()}>
            {_("translate", language)}
          </button>
          <button className={styles.btn} onClick={() => fetchSynonyms()}>
            {_("synonyms", language)}
          </button>
        </div>
        {bannerErr.length > 0 && (
          <div className={styles.errorBanner}>{_(bannerErr, language)}</div>
        )}

        <div className={styles.tags_inputs}>
          <div className={styles.formInput}>
            <label>{_("name_fr", language)}</label>
            <input
              className={nameErr.length > 0 ? styles.err : ""}
              type="text"
              value={name.fr}
              onChange={(e) => setName({ ...name, fr: e.target.value })}
            />
          </div>
          <div className={styles.formInput}>
            <label>{_("name_en", language)}</label>
            <input
              className={nameErr.length > 0 ? styles.err : ""}
              type="text"
              value={name.en}
              onChange={(e) => setName({ ...name, en: e.target.value })}
            />
          </div>
          <div className={styles.formInput}>
            <label>{_("name_nl", language)}</label>
            <input
              className={nameErr.length > 0 ? styles.err : ""}
              type="text"
              value={name.nl}
              onChange={(e) => setName({ ...name, nl: e.target.value })}
            />
          </div>
        </div>
        <div className={styles.nameErr}>
          {nameErr.length > 0 && _(nameErr, language)}
        </div>
        <div className={styles.synonyms_area}>
          <div className={styles.title}>{_("synonyms_list", language)}</div>
          <button
            className={classNames(styles.btn, styles.add_btn)}
            onClick={() => addSynonym()}
          >
            <IconAdd /> {_("add_synonym", language)}
          </button>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{_("name_fr", language)}</th>
                <th>{_("name_en", language)}</th>
                <th>{_("name_nl", language)}</th>
                <th>{_("remove", language)}</th>
              </tr>
            </thead>
            <tbody>
              {synonyms.map((synonym, i) => (
                <tr className={i % 2 === 0 ? styles.grey : ""}>
                  <td>
                    <input
                      type="text"
                      value={synonym.nameFr}
                      onChange={(e) =>
                        synonymChange(synonym.uid, e.target.value, "nameFr")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={synonym.nameEn}
                      onChange={(e) =>
                        synonymChange(synonym.uid, e.target.value, "nameEn")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={synonym.nameNl}
                      onChange={(e) =>
                        synonymChange(synonym.uid, e.target.value, "nameNl")
                      }
                    />
                  </td>
                  <td>
                    <IconDelete
                      onClick={() =>
                        setSynonyms(
                          synonyms.filter((el) => el.uid !== synonym.uid)
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.settings}>
          <div className={styles.selectInput}>
            <label>{_("status", language)}</label>
            <Select
              classNamePrefix="react-select"
              styles={SELECT_STYLES}
              isLoading={false}
              options={statusOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={status}
              onChange={(e) => setStatus(e)}
              isSearchable={false}
            />
          </div>
          <div className={styles.selectInput}>
            <label>{_("state", language)}</label>
            <Select
              classNamePrefix="react-select"
              styles={SELECT_STYLES}
              isLoading={false}
              options={stateOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={state}
              onChange={(e) => setState(e)}
              isSearchable={false}
            />
          </div>
          {synonymOf ? (
            <div className={styles.synonymOf}>
              <label>{_("is_synonym_of", language)}</label>
              <div className={styles.flex}>
                {nameAttr(synonymOf)}{" "}
                <IconClose onClick={() => setSynonymOf(null)} />
              </div>
            </div>
          ) : (
            <div className={styles.formInput}>
              <label>{_("is_synonym_of", language)}</label>
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {(isLoading || data) && (
                <ul className={styles.list}>
                  {isLoading && (
                    <li className={styles.loading}>
                      <Loader
                        style={{
                          height: "10px",
                        }}
                        color={"rgb(80, 129, 190)"}
                      />
                    </li>
                  )}
                  {data &&
                    data.map((el) => (
                      <li key={el.id} onClick={() => setSynonymOf(el)}>
                        {nameAttr(el)}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          )}
        </div>
        <div className={styles.associate}>
          <span>{_("associate_to_super_tag", language)}</span>
          <div className={styles.select_container}>
            <AsyncSelect
              cacheOptions
              isClearable
              value={superTag}
              styles={SELECT_STYLES}
              onChange={(e) => setSuperTag(e)}
              loadOptions={fetchSuperTags}
              placeholder={_("select_super_tag", language)}
              classNamePrefix="react-select"
              isDisabled={isSuperTag}
            />
          </div>
        </div>
        <div className={styles.extra_settings}>
          <div className={styles.formInput}>
            <input
              type="checkbox"
              name="check_2"
              id="check_2"
              checked={isSuperTag}
              onChange={() => {
                if (isSuperTag) {
                  setTheme(null);
                  setPages([]);
                }
                setSuperTag(null);
                setIsSuperTag(!isSuperTag);
              }}
            />
            <label htmlFor="check_2">{_("is_super_tag", language)}</label>
          </div>

          <div className={styles.formInput}>
            <input
              type="checkbox"
              name="check_3"
              id="check_3"
              checked={isAbbreviation}
              onChange={() => setIsAbbreviation(!isAbbreviation)}
            />
            <label htmlFor="check_3">{_("is_abbreviation", language)}</label>
          </div>
          <div className={styles.formInput}>
            <input
              type="checkbox"
              name="check_4"
              id="check_4"
              checked={isUniversal}
              onChange={() => setIsUniversal(!isUniversal)}
            />
            <label htmlFor="check_4">{_("is_universal", language)}</label>
          </div>

          <div className={styles.flex}>
            <div className={styles.themeSelect}>
              <label>{_("theme", language)}</label>
              <div className={styles.select_container}>
                <Select
                  classNamePrefix="react-select"
                  styles={SELECT_STYLES}
                  isLoading={loadingThemes}
                  options={themes}
                  filterOption={customFilter}
                  placeholder={_("select_theme", language)}
                  value={theme}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.id}
                  onChange={handleChangeTheme}
                  onMenuScrollToBottom={() => setStartThemes(startThemes + 20)}
                />
              </div>
            </div>
            <div className={styles.themeSelect}>
              <label>{_("pages", language)}</label>
              <div className={styles.select_container}>
                <Select
                  classNamePrefix="react-select"
                  styles={SELECT_STYLES}
                  isLoading={loadingPages}
                  options={pagesOptions}
                  placeholder={_("select_page", language)}
                  value={pages}
                  className={styles.input}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => setPages(e)}
                  isMulti
                  isDisabled={!theme || !pagesOptions}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.actions, styles.bottom)}>
          <button className={styles.btn} onClick={() => resetData()}>
            {_("cancel", language)}
          </button>
          <button className={styles.btn} onClick={() => handleSave()}>
            {_("save", language)}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTagModal;
