const fr = {
  welcome: "Bienvenu",
  logout: "Se Déconnecter",
  login: "Connexion",
  tags_list: "Liste des tags",
  filters: "Filtres",
  add_tag: "Ajouter un tag",
  previous: "Précédent",
  next: "Suivant",
  merge: "Fusionner",
  clean_tags: "Nettoyer les tags",
  search_tag: "Rechercher une tag",
  name: "Nom",
  handled: "Géré",
  usage_counter: "Compteur d'utilisation",
  synonyms: "Synonymes",
  state: "État",
  languages: "Langues",
  actions: "Actions",
  no_data_available_in_table: "Aucune donnée disponible dans la table",
  not_handled: "Non géré",
  not_validated: "Non validé",
  has_synonyms: "A des synonymes",
  showing: "Montrant",
  to: "à",
  of: "de",
  name_required: "Nom obligatoire",
  tag_exists: "Le tag existe",
  update_tag: "Mettre à jour le tag",
  translate: "Traduire",
  name_fr: "Nom Fr",
  name_en: "Nom En",
  name_nl: "Nom Nl",
  synonyms_list: "Liste des synonymes",
  add_synonym: "Ajouter un synonyme",
  remove: "Supprimer",
  TO_VALIDATE: "A valider",
  TREATED: "Traité",
  UNTREATED: "Non traité",
  is_synonym_of: "Est synonyme de",
  is_abbreviation: "Est une abréviation",
  is_universal: "Est universel",
  cancel: "Annuler",
  save: "Enregistrer",
  merge_question: "Êtes-vous sûr de vouloir fusionner",
  and: "et",
  keep: "Garder",
  as_principle: "en tant que tag principale",
  merge_and_delete: "Fusionner et supprimer",
  similars_tag_list: "Liste de tags similaires",
  similars_fr: "Similaires Fr",
  similars_en: "Similaires Er",
  similars_nl: "Similaires Nl",
  saved_successfully: "Enregistré avec succès",
  error_occurred: "Une erreur s'est produite",
  deleted_successfully: "Supprimé avec succès",
  tags_cleaned_successfully: "Tags nettoyées avec succès",
  is_super_tag: "Est un super tag",
  theme: "Thème",
  pages: "Pages",
  select_theme: "Sélectionner un thème",
  select_page: "Sélectionner une page",
  associate_to_super_tag: "Associer à un super tag",
  select_super_tag: "Sélectionner un super tag",
  hide_synonyms: "Cacher les synonymes",
};
export default fr;
