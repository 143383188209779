import React from "react";
import styles from "./HomePage.module.scss";
import _ from "../../i18n";
import { useSelector } from "react-redux";

const Pagination = ({ start, list, nbResults, paginationBtns }) => {
  const language = useSelector((state) => state.auth.language);
  return (
    <div className={styles.pagination_container}>
      <span className={styles.text_span}>
        {_("showing", language)} {start + 1} {_("to", language)} {start + list.length} {_("of", language)} {nbResults}{" "}
        tags
      </span>
      {paginationBtns()}
    </div>
  );
};

export default Pagination;
